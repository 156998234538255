import React, { createContext, useCallback, useContext, useReducer } from 'react';
import dayjs from 'dayjs';
import type { TaskFormState } from '~/models/task';
import type { LeadFormState, CampValueMap, PartialLeadFormState } from '~/models/lead';
import type { TodoFormState } from '~/models/todo';
import type { SubUserFormState } from '~/models/subUser';
import type { ClosureFormState } from '~/models/closures';

type ActiveTab = "task" | "todo" | "lead" | "subUser" | "closure"
type SetTask = { tab: "task", data?: { task?: TaskFormState, defTask?: Partial<TaskFormState> } }
type SetLead = { tab: "lead", data?: { lead?: LeadFormState, campValues?: CampValueMap, defLead?: Partial<LeadFormState> } }
type SetTodo = { tab: "todo", data?: TodoFormState }
type SetSubUser = { tab: "subUser", data?: SubUserFormState }
type SetClosure = { tab: "closure", data?: { closure?: ClosureFormState, defClosure?: Partial<ClosureFormState>, actClientInfo?: LeadFormState, cliid?: number[] } }

type FormModalArgs = SetTask | SetLead | SetTodo | SetSubUser | SetClosure

type FormModalContextValues = {
    open: boolean
    defTask: TaskFormState
    selectedTask: TaskFormState | undefined
    defLead: LeadFormState
    selectedLead: LeadFormState | undefined
    campValues: CampValueMap
    selectedTodo: TodoFormState | undefined
    selectedSubUser: SubUserFormState | undefined
    defClosure: ClosureFormState
    selectedClosure: ClosureFormState | undefined
    actClientInfo?: LeadFormState
    cliid?: number[]
    activeTab: ActiveTab
    handleFormModal: ({ tab, data }: FormModalArgs) => void
    setOpen: (open: boolean) => void
    setTab: (tab: ActiveTab) => void
    creatingTask: boolean
    setCreatingTask: (creating: boolean) => void
    handleClose: () => void
}

const FormModalContext = createContext<FormModalContextValues | undefined>(undefined)


export const useFormModalContext = () => {
    const context = useContext(FormModalContext)
    if (!context) {
        throw new Error('useFormModalContext must be used within a FormModalProvider')
    }
    return context
}

type State = Omit<FormModalContextValues, "handleFormModal" | "setOpen" | "setTab" | "setCreatingTask" | "handleClose">

type Action =
    | { type: 'SET_TASK'; payload?: { task?: TaskFormState, defTask?: Partial<TaskFormState> } }
    | { type: 'SET_LEAD'; payload?: { lead?: LeadFormState, campValues?: CampValueMap, defLead?: Partial<PartialLeadFormState> } }
    | { type: 'SET_TODO'; payload?: TodoFormState }
    | { type: 'SET_SUBUSER'; payload?: SubUserFormState }
    | { type: 'SET_CLOSURE'; payload?: { closure?: ClosureFormState, defClosure?: Partial<ClosureFormState>, actClientInfo?: LeadFormState, cliid?: number[] } }
    | { type: 'SET_ACTIVE_TAB'; payload: ActiveTab }
    | { type: 'SET_OPEN'; payload: boolean }
    | { type: 'SET_CREATING_TASK'; payload: boolean }
    | { type: 'CLEAR' }

const formModalReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_TASK':
            return { ...state, defTask: { ...state.defTask, ...action.payload?.defTask }, selectedTask: action.payload?.task, open: true, activeTab: 'task' };
        case 'SET_LEAD':
            return { ...state, defLead: { ...state.defLead, ...action.payload?.defLead }, selectedLead: action.payload?.lead, campValues: action.payload?.campValues || {}, open: true, activeTab: 'lead' };
        case 'SET_TODO':
            return { ...state, selectedTodo: action.payload, open: true, activeTab: 'todo' };
        case 'SET_SUBUSER':
            return { ...state, selectedSubUser: action.payload, open: true, activeTab: 'subUser' };
        case 'SET_CLOSURE':
            return { ...state, defClosure: { ...state.defClosure, ...action.payload?.defClosure }, selectedClosure: action.payload?.closure, actClientInfo: action.payload?.actClientInfo, cliid: action.payload?.cliid, open: true, activeTab: 'closure' };
        case 'SET_ACTIVE_TAB':
            return { ...state, activeTab: action.payload };
        case 'SET_OPEN':
            return { ...state, open: action.payload };
        case 'SET_CREATING_TASK':
            return { ...state, creatingTask: action.payload }
        case 'CLEAR':
            return { ...state, selectedTask: undefined, selectedLead: undefined, selectedTodo: undefined, selectedSubUser: undefined, selectedClosure: undefined, campValues: {}, defTask: deftaskData, defLead: defLeadData, open: false, activeTab: 'task' }
        default:
            return state;
    }
};

const deftaskData: TaskFormState = {
    TTAID: 1,
    CLIID: [],
    TARCONTENIDO: "",
    TARPRIVADA: 0,
    TARTIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
    TARVENCIMIENTO: "",
    REPID: null,
    TARHINI: "",
    TARHFIN: "",
    TARDIRECCION: "",
    TARLINK: "",
    TAREMAIL: "",
    TARMENSAJE: "",
    NOTAS: [],
    RECORDATORIOS: [],
    SUBT: [],
}

const defLeadData: LeadFormState = {
    CESID: 5,
    CLINOMBRE: "",
    CLIAPELLIDO: "",
    CLICONTACTO: "",
    CLIEMAIL1: "",
    CLIFNACIMIENTO: dayjs().format("YYYY-MM-DD"),
    CLINOTAS: "",
    CLICLAVES: null,
}

const defClosureData: ClosureFormState = {
    PERCONID: 1,
    CIENOTA: "",
    CIETITULO: "",
    CIEARC: [],
}

export const FormModalProvider: React.FC = ({ children }) => {
    const initialState: State = {
        open: false,
        defTask: deftaskData,
        selectedTask: undefined,
        defLead: defLeadData,
        selectedLead: undefined,
        campValues: {},
        selectedTodo: undefined,
        selectedSubUser: undefined,
        defClosure: defClosureData,
        selectedClosure: undefined,
        activeTab: "task",
        creatingTask: false,
        cliid: undefined,
    }

    const [state, dispatch] = useReducer(formModalReducer, initialState);
    const { activeTab, open, defTask, selectedTask, defLead, selectedLead, campValues, selectedTodo, selectedSubUser, defClosure, selectedClosure, actClientInfo, creatingTask, cliid } = state

    const handleFormModal = useCallback(({ tab, data }: FormModalArgs) => {
        switch (tab) {
            case "task":
                const { task, defTask } = data || {}
                const fallbackTimes = {
                    TARHINI: defTask?.TARHINI || dayjs().format('HH:mm:ss'),
                    TARHFIN: defTask?.TARHFIN || dayjs().add(0.25, 'hour').format('HH:mm:ss'),
                    TARVENCIMIENTO: defTask?.TARVENCIMIENTO || dayjs().format('YYYY-MM-DD')
                }
                dispatch({ type: 'SET_TASK', payload: { task, defTask: { ...defTask, ...fallbackTimes } } })
                break
            case "lead":
                dispatch({ type: 'SET_LEAD', payload: data })
                break
            case "todo":
                dispatch({ type: 'SET_TODO', payload: data })
                break
            case "subUser":
                dispatch({ type: 'SET_SUBUSER', payload: data })
                break
            case "closure":
                dispatch({ type: 'SET_CLOSURE', payload: data })
            default:
                break
        }
    }, [])

    const setTab = useCallback((tab: ActiveTab) => {
        dispatch({ type: 'SET_ACTIVE_TAB', payload: tab })
    }, [])

    const setOpen = useCallback((open: boolean) => {
        dispatch({
            type: 'SET_OPEN', payload: open
        })
    }, [])

    const setCreatingTask = useCallback((creating: boolean) => {
        dispatch({
            type: 'SET_CREATING_TASK', payload: creating
        })
    }, [])

    const handleClose = useCallback(() => {
        dispatch({ type: 'CLEAR' })
    }, [])


    return (
        <FormModalContext.Provider
            value={{
                open,
                defTask,
                selectedTask,
                defLead,
                selectedLead,
                campValues,
                selectedTodo,
                selectedSubUser,
                defClosure,
                selectedClosure,
                actClientInfo,
                cliid,
                activeTab,
                creatingTask,
                setCreatingTask,
                handleFormModal,
                setOpen,
                setTab,
                handleClose
            }}
        >
            {children}
        </FormModalContext.Provider>
    );
}